<template>
  <el-upload
    ref="upload"
    drag
    action="https://upload-z2.qiniup.com/"
    :file-list="files"
    :limit="replace ? undefined : limit"
    v-bind="$attrs"
    :accept="accept"
    :before-upload="beforeUpload"
    :http-request="httpRequest"
    :on-success="handleSuccess"
    :on-error="handleError"
    :on-remove="handleRemove"
    :on-preview="handlePreview"
    :on-exceed="handleExceed"
    :on-progress="handleProgress"
    :disabled="disabled"
    :show-file-list="showFileList"
  >
    <template #trigger>
      <slot name="trigger">
        <div class="upload-box flex-column">
          <div class="upload-title flex-center f16-bold operation-add--dark">
            上传文件
          </div>
          <div class="upload-tip f14-light mt8">
            请上传ppt、word、excel、pdf、txt、图片、视频、压缩格式的文件单个文件不超过1GB
          </div>
        </div>
      </slot>
    </template>
    <template #file="{ file }">
      <slot name="file" :file="file" />
    </template>
  </el-upload>
</template>

<script>
import {
  EXCEL_SUFFIX,
  IMG_SUFFIX,
  PDF_SUFFIX,
  PPT_SUFFIX,
  TXT_SUFFIX,
  VIDEO_SUFFIX,
  WORD_SUFFIX,
  ZIP_SUFFIX,
  getBelong,
} from '@shared/constants/file'
import { REG_SPACE } from '@shared/constants/regular'
import { GB_IN_BYTE, getSize } from '@shared/util/byte'
import { addClass } from '@shared/util/dom'
import download from '@shared/util/download'
import { getPromise } from '@shared/util/promise'
import { useQiniu } from '@qs-plus/compositions/useQiniu'
import { RequestError } from '@shared/constants/error'

export default {
  props: {
    accept: {
      type: String,
      default: []
        .concat(
          PPT_SUFFIX,
          WORD_SUFFIX,
          EXCEL_SUFFIX,
          PDF_SUFFIX,
          TXT_SUFFIX,
          ZIP_SUFFIX,
          IMG_SUFFIX,
          VIDEO_SUFFIX,
        )
        .join(', '),
    },
    extraAccept: {
      type: String,
      default: '',
    },
    fileList: {
      type: Array,
      required: true,
      default: () => [],
    },
    size: {
      type: Number,
      default: GB_IN_BYTE,
    },
    minSize: {
      type: Number,
      default: 0,
    },
    replace: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: [Number, undefined],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    getUploadToken: {
      type: Function,
      default: () => Promise.resolve(true),
    },
    hasPreview: {
      type: Boolean,
      default: false,
    },
    exceedMessage: {
      type: String,
      default: '',
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:fileList',
    'innerFileListChange',
    'preview',
    'progress',
    'percent',
    'uploaded',
    'uploading',
    'error',
    'errorFn',
    'remove',
  ],
  setup() {
    return {
      download,
    }
  },
  data() {
    return {
      files: [],
      fileListClone: [],
      speedCalInfo: {
        startTime: null,
        currentTime: null,
      },
    }
  },
  watch: {
    files() {
      this.updateIcon()
    },
    fileList: {
      handler() {
        if (
          this.fileList !== this.fileListClone
          || this.fileList.length !== this.files.length
        ) {
          this.fileListClone = this.fileList
          this.files = [...this.fileListClone]
          this.$emit('innerFileListChange', this.fileListClone)
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateIcon() {
      Promise.resolve().then(() => {
        const domList = this.$refs.upload.$el.querySelectorAll(
          '.el-upload-list__item-name',
        )
        const isDomDel = domList.length > this.limit && this.replace
        for (
          let i = domList.length - this.files.length;
          i < domList.length;
          i++
        ) {
          addClass(
            domList[i],
            `file__${getBelong(
              this.files[
                isDomDel || i >= this.files.length ? i - this.files.length : i
              ]?.name,
            )}`,
          )
        }
      })
    },
    handleExceed() {
      this.$error(this.exceedMessage || `仅支持上传${this.limit}个文件，请删除后再上传`)
    },
    beforeUpload(file) {
      const ext = `.${file.name.split('.').pop()}`
      if (this.accept
          && this.accept !== '*'
          && !this.accept.replace(REG_SPACE, '').split(',').includes(ext)) {
        this.$error(`不支持当前格式文件上传，请上传 ${this.accept} 格式的文件`)
        return false
      }
      if (this.extraAccept
          && this.extraAccept !== '*'
          && !this.extraAccept.replace(REG_SPACE, '').split(',').includes(ext)) {
        this.$error(`不支持当前格式文件上传，请上传 ${this.extraAccept} 格式的文件`)
        return false
      }
      if (file.size > this.size) {
        this.$error(`上传的文件大小请不要超过${getSize(this.size)}`)
        return false
      }
      if (file.size < this.minSize) {
        this.$error(`上传的文件大小请大于${getSize(this.minSize)}`)
        return false
      }
      return true
    },
    httpRequest(request) {
      this.$emit('uploading', request.file)
      const { uploadData, uploadFile, uploadAbort } = useQiniu({
        size: this.size,
        getUploadToken: this.getUploadToken,
      })
      const { promise } = getPromise()
      uploadFile(request.file, {
        next: ({ total }) => {
          this.$emit('percent', total.percent)
          request.onProgress({ percent: total.percent })
        },
        error: e => request.onError(e),
        complete: () => request.onSuccess(uploadData),
      }).then(success => !success && request.onError(success))
      request.file.uploadAbort = () => {
        uploadAbort()
      }
      promise.abort = uploadAbort
      return promise
    },

    handleError(err, file, files) {
      err ? this.$error(new RequestError(err).message || '上传失败') : this.$emit('errorFn')
      this.$emit('innerFileListChange', files)
      this.$emit('update:fileList', files)
      this.$emit('error')
    },
    handleSuccess(response, file, files) {
      file.url = response.downloadUrl
      const isReplace = this.replace && files.length > this.limit
      this.files = files
      this.fileListClone = files.slice(isReplace ? -this.limit : 0)
      this.$emit('innerFileListChange', this.fileListClone)
      this.$emit('update:fileList', this.fileListClone)
      this.$emit('uploaded', file)
    },
    handleProgress(_event, file, files) {
      this.$emit('innerFileListChange', files)
      this.$emit('progress', file, files)
    },
    handlePreview(file) {
      if (this.hasPreview) {
        this.$emit('preview', file)
      } else {
        this.download(file)
      }
    },
    handleRemove(file, files) {
      this.fileListClone = files.filter(v => v.url !== file.url || v.uid !== file.uid)
      file?.raw?.uploadAbort?.()
      this.files = [...this.fileListClone]
      this.$emit('innerFileListChange', this.fileListClone)
      this.$emit('update:fileList', this.fileListClone)
      this.$emit('remove', file)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@shared/style/color';

.upload-box {
  padding: 1em;
  width: 100%;
  height: 100%;
  white-space: normal;
  color: $dark-light;
  justify-content: center;
}

:deep(.el-upload) {
  max-width: 100%;

  .el-upload-dragger,
  .el-upload-dragger:hover {
    border-color: $dark-light;
    width: 598px;
    max-width: 100%;
    height: 88px;
  }
  .el-upload__input {
    display: none;
  }
}
:deep(.el-upload-list) {
  .el-upload-list__item-name {
    display: flex;
    color: $dark-medium;
    align-items: center;

    .el-icon--document {
      width: 1.8em;
      height: 1.8em;
      * {
        display: none;
      }
    }
    .el-icon--document::before {
      display: inline-block;
      width: 100%;
      height: 100%;
      background: no-repeat center/contain url('@shared/img/file/common.png');
      content: ' ';
    }
  }

  $files: excel, img, pdf, ppt, video, word, zip;

  @each $file in $files {
    .file__#{$file} .el-icon--document::before {
      background-image: url('@shared/img/file/#{$file}.png');
    }
  }
}
</style>
